import { useContext, useEffect, useState, createContext, useCallback } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import { getCompanies } from "./requests/companies/get-companies";
import { useAuth } from "../../context/AuthContext";
import { openCashRegister } from "./requests/cashRegister/open-cash-register";
import { openAllCashRegister } from "./requests/cashRegister/open-all-cash-register";
import { getOrders } from "./requests/orders/getOrders";
import { getAmountOfScheduledOrders } from "./requests/orders/getAmountOfScheduledOrders";
import { api } from "../../services/api";
import { closeAllCashRegister } from "./requests/cashRegister/close-all-cash-register";
import { closeCashRegister } from "./requests/cashRegister/close-cash-register";
import { apiInovesystem } from "../../services/inovesystem";
import moment from "moment";

// ---- Notification songs
import nSong from "../../assets/som.mp3";
import eSong from "../../assets/error.mp3";
import { getOrderInfos } from "./requests/orders/getOrderInfos";
import { openStore } from "./requests/companies/openStore";
import { openAllStore } from "./requests/companies/openAllStore";
import { postVault } from "./requests/vault/postVault";
const notifySong = new Audio(nSong);
const errorSong = new Audio(eSong);

const OrderManagerContext = createContext();
export function OrderManagerProvider({ children }) {
	// ---- Hooks
	const history = useHistory();
	const { token } = useAuth();

	// ---- States
	const [showOpenCashRegister, setShowOpenCashRegister] = useState(true);
	const [showCloseCashRegister, setShowCloseCashRegister] = useState(false);
	const [showLinksStores, setShowLinksStores] = useState(false);
	const [showCalculateRoutes, setShowCalculateRoutes] = useState(false);
	const [showChangeLanguage, setShowChangeLanguage] = useState(false);
	const [companies, setCompanies] = useState([]);
	const [companiesIsLoading, setCompaniesIsLoading] = useState(true);
	const [cashRegisterIsLoading, setCashRegisterIsLoading] = useState(false);
	const [orders, setOrders] = useState([]);
	const [ordersIsLoading, setOrdersIsLoading] = useState(false);
	const [currentOrder, setCurrentOrder] = useState(null);
	const [currentOrderInfos, setCurrentOrderInfos] = useState(null);
	const [orderIsPrinting, setOrderIsPrinting] = useState(false);
	const [scheduledOrdersAmount, setScheduledOrdersAmount] = useState(0);
	const [showSchedulingModal, setShowSchedulingModal] = useState(false);
	const [showManageStoresModal, setShowManageStoresModal] = useState(false);
	const [storeIsOpening, setStoreIsOpening] = useState(false);
	const [choseMotoboy, setChoseMotoboy] = useState(false);
	const [motoboyDialogInfo, setMotoboyDialogInfo] = useState(null);
	const [schedulesOrders, setSchedulesOrders] = useState([]);
	const [schedulesOrdersIsLoading, setSchedulesOrdersIsLoading] = useState(true);
	// Route
	const [selectedBusiness, setSelectedBusiness] = useState(null);
	const [orderRouterSelected, setOrderRouterSelected] = useState([]);
	const [calculateRouteStep, setCalculateRouteStep] = useState(1);
	const [routeMapGenerated, setRouteMapGenerated] = useState("");
	const [routeMapRoutes, setRouteMapRoutes] = useState([]);
	const [routeMapIsLoading, setRouteMapIsLoading] = useState(false);

	// ---- Functions
	function handleSelectCompany(companyId) {
		const newCompanies = companies.map((company) => {
			if (company.cdEmpresa === companyId) {
				return { ...company, selected: !company.selected };
			}
			return company;
		});
		setCompanies(newCompanies);
	}

	async function handleOpenCashRegister({ companyId, vaultId, amount }) {
		try {
			setCashRegisterIsLoading(true);
			await openCashRegister({ companyId, token, amount });
			await postVault({ company: companyId, vaultId, amount, token });
			const newCompanies = companies.map((company) => {
				if (company.cdEmpresa === companyId) {
					return { ...company, cashRegisterOpen: true, selected: true };
				}
				return company;
			});
			setCompanies(newCompanies);
		} catch (error) {
			toast.warn(error.message);
		} finally {
			setCashRegisterIsLoading(false);
		}
	}

	async function handleOpenAllCashRegister({ amount }) {
		try {
			setCashRegisterIsLoading(true);
			await openAllCashRegister({ token, companies, amount });
			const newCompanies = companies.map((company) => {
				return { ...company, cashRegisterOpen: true, selected: true };
			});
			setCompanies(newCompanies);
		} catch (error) {
			toast.warn(error.message);
		} finally {
			setCashRegisterIsLoading(false);
		}
	}

	function handleFinishCashRegisterManagement() {
		if (!companies.some((company) => company.selected)) {
			toast.warn("Selecione ao menos uma empresa");
			return;
		}

		handleGetOrders();
		OpenCompanies();
		setShowOpenCashRegister(false);
	}

	async function handleGetOrders(notLoading = false) {
		if (notLoading !== true) {
			setOrdersIsLoading(true);
		}

		const selectedCompanies = companies.filter((company) => company.selected === true);

		if (selectedCompanies.length === 0) {
			setShowOpenCashRegister(true);
			return;
		}

		const companiesId = selectedCompanies.map((item) => item.cdEmpresa);

		try {
			// Getting orders
			const newOrders = await getOrders(companiesId);
			setOrders(newOrders);
			// Getting amount of scheduled orders
			const ordersScheduledAmount = await getAmountOfScheduledOrders(companiesId);
			setScheduledOrdersAmount(ordersScheduledAmount);

			setSchedulesOrdersIsLoading(true);
			const { data: ordersScheduled } = await api.get("/getPurchaseRequests/schedule", {
				params: {
					companies: companiesId.join(","),
				},
			});
			setSchedulesOrders(ordersScheduled);
			setSchedulesOrdersIsLoading(false);

			// // Verify if has new purchase pending, if true, Notify the user
			if (
				orders.filter((order) => order.dsStatus === "A") <
				newOrders.filter((newOrder) => newOrder.dsStatus === "A")
			) {
				notify();

				if (document.visibilityState === "hidden") {
					const titleTimer = setInterval(() => {
						if (document.title === "iNove Clube") {
							document.title = "Novo pedido!!!";
						} else {
							document.title = "iNove Clube";
						}
					}, 2000);

					const clearTimer = () => {
						if (document.visibilityState === "visible") {
							document.title = "iNove Clube";
							clearInterval(titleTimer);
						}
					};

					document.removeEventListener("visibilitychange", clearTimer);
					document.addEventListener("visibilitychange", clearTimer);
				}
			}
		} catch (error) {
			console.log(error);

			if (error.message === "Network Error") {
				toast.error("Conexão perdida com a interner!");

				if (!("Notification" in window)) {
					alert("Este navegador não suporta notificações");
				} else if (Notification.permission === "granted") {
					errorSong.play();
				} else if (Notification.permission !== "denied") {
					Notification.requestPermission().then((permission) => {
						if (permission === "granted") {
							errorSong.play();
						}
					});
				}

				return;
			}

			toast.error("Falha ao carregar pedidos!");
		} finally {
			setOrdersIsLoading(false);
		}
	}

	function notify() {
		if (!("Notification" in window)) {
			alert("Este navegador não suporta notificações");
		} else if (Notification.permission === "granted") {
			notifySong.play();
		} else if (Notification.permission !== "denied") {
			Notification.requestPermission().then((permission) => {
				if (permission === "granted") {
					notifySong.play();
				}
			});
		}
	}

	async function OpenCompanies() {
		if (companies.length === 0) return;

		try {
			const selectedCompanies = companies.filter((company) => company.selected === true);
			const companiesId = selectedCompanies.map((item) => item.cdEmpresa);
			if (companiesId.length !== 0) {
				await api.post("/keepCompaniesOpen", {
					companies: companiesId.join(","),
				});
			}
			setCompanies(
				companies.map((company) => {
					if (company.selected) {
						return { ...company, lojaAberta: true };
					}
					return { ...company, lojaAberta: false };
				}),
			);
		} catch (error) {
			console.log(error);
			if (error.message === "Network Error") {
				toast.error("Conexão perdida com a interner!");
			} else {
				toast.error("Algo deu errado!");
			}
		}
	}

	async function keepCompanyOpen() {
		if (companies.length === 0 || showOpenCashRegister) return;

		try {
			const companiesOpen = companies.filter((item) => item.lojaAberta === true);
			const cdEmpresaToQuery = companiesOpen.map((item) => item.cdEmpresa);
			if (cdEmpresaToQuery.length !== 0) {
				await api.post("/keepCompaniesOpen", {
					companies: cdEmpresaToQuery.join(","),
				});
			}
		} catch (error) {
			console.log(error);
			if (error.message === "Network Error") {
				toast.error("Conexão perdida com a interner!");
			} else {
				toast.error("Algo deu errado!");
			}
		}
	}

	async function handleCloseCashRegister({ companyId, installments }) {
		try {
			setCashRegisterIsLoading(true);
			await closeCashRegister({ companyId, token, installments });
			const newCompanies = companies.map((company) => {
				if (company.cdEmpresa === companyId) {
					return { ...company, cashRegisterOpen: false, selected: false };
				}
				return company;
			});
			setCompanies(newCompanies);
		} catch (error) {
			toast.warn("Falha ao fechar caixa!");
		} finally {
			setCashRegisterIsLoading(false);
		}
	}

	async function handleCloseAllCashRegister() {
		try {
			setCashRegisterIsLoading(true);
			await closeAllCashRegister({ token, companies });
			const newCompanies = companies.map((company) => {
				return { ...company, cashRegisterOpen: false, selected: false };
			});
			setCompanies(newCompanies);
		} catch (error) {
			toast.warn("Falha ao fechar os caixas!");
		} finally {
			setCashRegisterIsLoading(false);
		}
	}

	async function printOrder(order) {
		try {
			setCurrentOrder(order);
			setCurrentOrderInfos(await getOrderInfos(order.idOrcamento));
			setOrderIsPrinting(true);
			await new Promise((resolve) => setTimeout(resolve, 100));
			window.print();
			setCurrentOrder(null);
			setCurrentOrderInfos(null);
			setOrderIsPrinting(false);
		} catch (error) {
			console.log(error);
			toast.error("Falha ao carregar dados para impressão!");
		}
	}

	async function handleOpenStore(companyId, status) {
		try {
			setStoreIsOpening(true);
			await openStore({ companyId, status });
			const newCompanies = companies.map((company) => {
				if (company.cdEmpresa === companyId) {
					return { ...company, lojaAberta: status };
				}
				return company;
			});
			setCompanies(newCompanies);
		} catch (error) {
			toast.warn(error.message);
		} finally {
			setStoreIsOpening(false);
		}
	}

	async function handleOpenAllStores() {
		try {
			setStoreIsOpening(true);
			const companiesWithCashRegisterOpen = companies.filter(
				(company) => company.cashRegisterOpen,
			);
			const companiesId = companiesWithCashRegisterOpen.map(
				(company) => company.cdEmpresa,
			);
			await openAllStore({ companiesId });
			const newCompanies = companies.map((company) => {
				if (company.cashRegisterOpen) {
					return { ...company, lojaAberta: true };
				}
				return company;
			});
			setCompanies(newCompanies);
		} catch (error) {
			toast.warn(error.message);
		} finally {
			setStoreIsOpening(false);
		}
	}

	const getInstallmentsAndKeys = useCallback(async () => {
		try {
			// Get companies that are not a franchise or business park
			const listOfBusinessToFilter = companies.filter((company) => company.selected);

			// Get the id of the companies to filter in a string
			const stringOfBusinessIds = listOfBusinessToFilter
				.map((item) => item.cdEmpresa)
				.join(",");

			// If there are no companies to filter, return
			if (!listOfBusinessToFilter.length) return;

			// Get installments and keys from the API
			const { data: installmentsResponse } = await apiInovesystem.get(
				`/getParcela/${stringOfBusinessIds}`,
			);

			const { data: keysResponse } = await apiInovesystem.get("/getKey/", {
				params: { idBusiness: stringOfBusinessIds },
			});

			// Map the companies to add the key to the object
			const businessWithKeys = listOfBusinessToFilter.map((business) => {
				const key = keysResponse.keys?.find(
					(key) => key.cdEmpresa === business.cdEmpresa,
				);
				return {
					...business,
					key: key ? key : null,
				};
			});

			// Map the installments to add the status to the object
			const businessWithInstallments = installmentsResponse.data?.map((item) => {
				function getStatus(item) {
					if (item.dtPagamento && item.dtPagamento !== "1899-12-30T00:00:00.000Z") {
						return "paid";
					}

					const daysDiff = moment().diff(item.dtVencimento, "days", true);

					switch (true) {
						case daysDiff > 0:
							return "late";
						case daysDiff > -0.9:
							return "expiringToday";
						case daysDiff > -6:
							return "delayed";
						default:
							return "open";
					}
				}

				return {
					...item,
					status: getStatus(item),
				};
			});

			// Verify if has business without access
			const hasBusinessWithoutAccess = businessWithKeys?.some((item) => {
				// If the key has a end date, verify if it is late
				if (item?.key?.dtFim) {
					const keyIsLate = moment().diff(item.key.dtFim, "days", true) > 0;
					if (keyIsLate) return true;
					return false;
				}

				// If the key has no end date, verify if the company has installments late
				const hasInstallmentsLate = businessWithInstallments?.some(
					(business) =>
						business.status === "late" && item.cdEmpresa === business.cdPessoa,
				);

				if (hasInstallmentsLate) return true;
				return false;
			});

			if (hasBusinessWithoutAccess) {
				history.push("/adm");
				return;
			}

			// Verify if has installments delayed
			const hasInstallmentsDelayed = businessWithInstallments?.some(
				(item) => item.status === ("delayed" || "late"),
			);

			if (hasInstallmentsDelayed) {
				history.push("/adm");
				return;
			}
		} catch (error) {
			console.log(`OrderManagerProvider::getInstallmentsAndKeys - ${error}`);
		}
	}, [history, companies]);

	// ---- Query
	useQuery(
		"orders",
		async () => {
			if (companies.length === 0 || showOpenCashRegister || showManageStoresModal) return;
			handleGetOrders(true);
			return;
		},
		{
			refetchInterval: 1000 * 60, // 2 minutes
			refetchIntervalInBackground: true,
		},
	);

	useQuery(
		"keepCompanyOpen",
		async () => {
			keepCompanyOpen();
			return;
		},
		{
			refetchInterval: 1000 * 60,
			refetchIntervalInBackground: true,
		},
	);

	// ---- Effect
	useEffect(() => {
		setCompaniesIsLoading(true);
		getCompanies(token)
			.then((companies) => {
				setCompanies(companies);
				setCompaniesIsLoading(false);
			})
			.catch((error) => {
				history.push("/profile");
				toast.warn(error.message);
			});
	}, [token, history]);

	// useEffect(() => {
	//   if (companies.length !== 0) {
	//     getInstallmentsAndKeys();
	//   }
	// }, [getInstallmentsAndKeys, companies]);

	return (
		<OrderManagerContext.Provider
			value={{
				showOpenCashRegister,
				companies,
				companiesIsLoading,
				handleSelectCompany,
				handleOpenCashRegister,
				handleOpenAllCashRegister,
				cashRegisterIsLoading,
				handleFinishCashRegisterManagement,
				ordersIsLoading,
				orders,
				scheduledOrdersAmount,
				notify,
				handleGetOrders,
				setShowOpenCashRegister,
				showCloseCashRegister,
				setShowCloseCashRegister,
				showLinksStores,
				setShowLinksStores,
				handleCloseCashRegister,
				handleCloseAllCashRegister,
				printOrder,
				orderIsPrinting,
				currentOrderInfos,
				currentOrder,
				setShowSchedulingModal,
				showSchedulingModal,
				setShowManageStoresModal,
				showManageStoresModal,
				storeIsOpening,
				handleOpenStore,
				handleOpenAllStores,
				choseMotoboy,
				setChoseMotoboy,
				motoboyDialogInfo,
				setMotoboyDialogInfo,
				getInstallmentsAndKeys,
				schedulesOrders,
				schedulesOrdersIsLoading,
				setShowCalculateRoutes,
				showCalculateRoutes,
				setShowChangeLanguage,
				showChangeLanguage,
				calculateRouteStep,
				setCalculateRouteStep,
				selectedBusiness,
				setSelectedBusiness,
				orderRouterSelected,
				setOrderRouterSelected,
				routeMapGenerated,
				setRouteMapGenerated,
				routeMapIsLoading,
				setRouteMapIsLoading,
				routeMapRoutes,
				setRouteMapRoutes,
			}}
		>
			{children}
		</OrderManagerContext.Provider>
	);
}

export function useOrderManagerContext() {
	const context = useContext(OrderManagerContext);
	if (!context) throw new Error("useOrderManagerContext must be used within an Provider");
	return context;
}
