import React, { useState } from "react";
import { useOrderManagerContext } from "../../../context";
import { SpaceLoader } from "../../../../../components/loaders/spaceLoader";
import {
	Button,
	CopyContent,
	RouteContent,
	RouteLoadingContent,
	RouteTable,
	RouteTableData,
	RouteTableHead,
	RouteTableHeader,
	RouteTableLabel,
} from "../style";
import { useTranslator } from "../../../../../context/TranslatorContext";

export default function GeneratedRoute() {
	const { getText } = useTranslator();

	const [copyText, setCopyText] = useState(false);

	const { routeMapGenerated, routeMapRoutes, routeMapIsLoading } =
		useOrderManagerContext();

	const handleCopyText = () => {
		if (copyText) return;
		setCopyText(true);
		navigator.clipboard.writeText(routeMapGenerated);
		setTimeout(() => {
			setCopyText(false);
		}, 3000);
	};

	return (
		<>
			{routeMapIsLoading && (
				<RouteLoadingContent>
					<SpaceLoader />
					<span>{getText("order-manager.calculate-route.calculating-route")}</span>
				</RouteLoadingContent>
			)}
			{!routeMapIsLoading && (
				<RouteContent>
					<RouteTableLabel>
						{getText("order-manager.calculate-route.calculated-routes")}
					</RouteTableLabel>
					<div>
						<RouteTable>
							<RouteTableHead>
								<tr>
									<RouteTableHeader style={{ width: "25%" }}>
										{getText("order-manager.calculate-route.distance")}
									</RouteTableHeader>
									<RouteTableHeader style={{ width: "25%" }}>
										{getText("order-manager.calculate-route.duration")}
									</RouteTableHeader>
									<RouteTableHeader style={{ width: "50%" }}>
										{getText("order-manager.calculate-route.address")}
									</RouteTableHeader>
								</tr>
							</RouteTableHead>
							<tbody>
								{routeMapRoutes?.map((item, index) => {
									return (
										<tr key={index}>
											<RouteTableData style={{ width: "25%" }}>
												{item.distance}
											</RouteTableData>
											<RouteTableData style={{ width: "25%" }}>
												{item.duration}
											</RouteTableData>
											<RouteTableData style={{ width: "50%" }}>
												{item.endAddress}
											</RouteTableData>
										</tr>
									);
								})}
							</tbody>
						</RouteTable>
					</div>
					<CopyContent>
						<Button type="button" onClick={() => handleCopyText()}>
							{copyText
								? getText("order-manager.calculate-route.copied")
								: getText("order-manager.calculate-route.copy")}
						</Button>
						<span>{getText("order-manager.calculate-route.map-link")}</span>
					</CopyContent>
				</RouteContent>
			)}
		</>
	);
}
