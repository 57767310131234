import styled from "styled-components";

export const Container = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.5);

  .space-x {
    display: flex;
    gap: 1rem;
  }

  .opacity-50 {
    opacity: 0.5;
  }
`;

export const Modal = styled.div`
  background-color: var(--gray-100);
  border-radius: 8px;
  padding: 2rem;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  max-height: 80%;
  overflow-x: auto;
  animation: modal 0.2s linear;
  display: flex;
  flex-direction: column;
  gap: 1.25rem;
  width: 50%;
  max-width: 700px;
  @keyframes modal {
    from {
      opacity: 0;
      transform: translateY(50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 1.5rem;
`;

export const Button = styled.button`
  padding: 0.5rem 1rem;
  border-radius: 8px;
  background-color: var(--brand-600);
  color: var(--gray-50);
  border: none;
  font-weight: 500;

  &:hover {
    filter: brightness(0.9);
  }

  &.right {
    align-self: flex-end;
  }

  &.large {
    font-size: 1.2rem;
  }
`;

export const CardRouter = styled.div`
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid var(--gray-200);
`;

export const CardContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`;

export const RouteContent = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

export const RouteTableLabel = styled.span`
  font-weight: bold;
  font-size: 1.25rem;
`;

export const RouteTable = styled.table`
  display: block;
  table-layout: fixed;
  width: 100%;
  max-height: 300px;
  overflow-y: auto;
  th:first-child {
    border-radius: 8px 0px 0px 0px;
  }
  th:last-child {
    border-radius: 0px 8px 0px 0px;
  }
`;

export const RouteTableHead = styled.thead`
  top: 0;
  z-index: 10;
  position: sticky;
`;

export const RouteTableHeader = styled.th`
  padding: 8px;
  background-color: var(--gray-300);
`;

export const RouteTableRow = styled.tr`

`;

export const RouteTableData = styled.td`
  padding: 8px;
  background-color: var(--gray-200);
  max-width: 400px;
`;

export const RouteLoadingContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
  margin: 16px 0;
`;

export const CardOrders = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  max-height: 250px;
  overflow-y: auto;
`;

export const CopyContent = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`;
